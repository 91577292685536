.row {
  flex-direction: row;
}

@media (max-width: 700px) {
  .row {
    flex-wrap: wrap;
  }
  .chunk {
    flex-basis: 100%;
  }
}