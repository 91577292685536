html, body, #root {
    margin: 0;
    height: 100%;
    overflow: hidden;
}

@media (prefers-color-scheme: dark) {
    html, body {
        background-color: #000;
        color: #fff;
    }
}